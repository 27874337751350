<template>
	<el-row :gutter="24">
		<el-col :span="17" style="background-color: white;">
			<div class="cct">
				<vue-video-player id="videoPlayer2" 
				class="video-player-box" 
				ref="videoPlayer"
				 autoplay 
				 :options="playerOptions"
				 :playsinline="true" 
				 customEventName="customstatechangedeventname" 
				 @play="onPlayerPlay" 
				 @pause="onPlayerPause"
				 @ended="onPlayerEnded" 
				 @waiting="onPlayerWaiting"
				  @playing="onPlayerPlaying"
				   @loadeddata="onPlayerLoadeddata"
				 @timeupdate="onPlayerTimeupdate"
				  @canplay="onPlayerCanplay" 
				  @canplaythrough="onPlayerCanplaythrough"
				   @statechanged="playerStateChanged"
				 @ready="playerReadied"
				  @fullscreen="onPlayerRequestFullscreen">

				</vue-video-player>
				<meta name="referrer" content="never">
			</div>
			<div class="text">
				<span>
					<el-input v-model="dm" placeholder="请输入内容" style="width: 80%;"></el-input>
				</span>
				<span>
					<div class="fasong" @click="oo()">发送</div>
				</span>
			</div>
			<div>
				适合腾讯、爱奇艺、优酷 使用方法:电脑网页类型找到需要播放的视频，复制地址栏的地址放进视频连接内即可手机APP类型点击视频右上方的三个点找到复制连接放进视频连接内即可
				<span style="color: red;">
					注意个别视频可能解析失败，可以换其他平台或者视频
				</span>
			</div>
		</el-col>
		<el-col :span="6">
	  	    <el-input v-model="splj" placeholder="视频连接"></el-input>
			<button class="fasong" @click="aa()" style="width: 100%;height: 50px;margin-top: 10px;">获取地址</button>
		</el-col>
	</el-row>


</template>

<script>
	import {
		videoPlayer
	} from 'vue-video-player'
	import 'video.js/dist/video-js.css'
	
	
	import {
		transmitterData,
		emailLogin,
		getIsRoom,
		getVideo
	} from '../../api';

	export default {

		// 视频格式
		// type："video/webm"   // 可以播放，用ogg也可打开
		// type："video/ogg"    // 可以播放，用webm也可打开
		// type："video/3gp"    // 可以播放
		// type："video/mp4"    // 可以播放
		// type："video/avi"    // 打不开 无法播放
		// type："video/flv"    // 打不开 可以使用flvjs代替
		// type："video/mkv"    // 打不开 使用video/mp4可以播放
		// type："video/mov"    // 打不开 使用video/mp4可以播放
		// type："video/mpg"    // 打不开 未测试
		// type："video/swf"    // 打不开  未测试
		// type："video/ts"     // 打不开 未测试
		// type："video/wmv"    // 打不开 未测试
		// type："video/vob"    // 没转化 未测试
		// type："video/mxf"    // 转化出错 未测试
		// type: "video/rm"     // 转化出错 未测试
		// // 组件方法
		// 重置进度条
		// this.player.src(src)
		// 加载视频
		// this.player.load();
		// 播放视频
		// this.player.play();
		// 暂停播放
		// this.player.pause();
		// 直接全屏 如果当前设备支持的话
		// this.player.requestFullscreen();
		// 在全屏模式下，将视频恢复到正常大小
		// this.player.exitFullscreen();
		// 当环境不支持全屏时 则扩展铺满容器
		// this.player.enterFullWindow();
		// 重置播放器
		// this.player.reset();
		// 返回当前播放源信息
		// this.player.currentSources();
		// 获取或者设置预加载属性
		// this.player.preload(val);
		// 播放控件是否显示
		// this.player.controls(false);
		/* 父组件可以通过获取当前组件的 player 来调用 videoPlayer 组件的方法 */

		components: {
			VueVideoPlayer: videoPlayer
		},
		data() {
			return {
				playerOptions: {
					preload: 'auto', //视频预加载
					// videojs options
					// 是否等浏览器准备好后自动播放
					autoplay: true,
					// 是否静音
					muted: false,
					// 结束后是否重新开始
					loop: false,
					// 语言
					language: 'en',
					// 播放速度可选列表
					playbackRates: [],
					// 播放视频源
					sources: [{
						type: 'video/mp4',
						src: ''
					}],
					// 视频比例
					// aspectRatio: '4:3',
					//  为 true 时, 播放器具有流畅的大小
					fluid: true,
					// 封面
					// poster: '/static/images/author.jpg'
					controlBar: {
						//当前时间和持续时间的分隔符
						timeDivider: true,
						//显示持续时间
						durationDisplay: true,
						//是否显示剩余时间功能
						remainingTimeDisplay: false,
						//全屏按钮
						fullscreenToggle: true,
						//播放暂停按钮
						playToggle: true,
						//音量控制
						volumeMenuButton: false,
						//当前播放时间
						currentTimeDisplay: true,
						//点播流时，播放进度条，seek控制
						progressControl: true,
						//直播流时，显示LIVE
						liveDisplay: true,
						//播放速率，当前只有html5模式下才支持设置播放速率
						playbackRateMenuButton: true

					}
				},
				arr: [],
				websocket: null,
				dm: "",
				splj:"",
				room: {
					roomid: "",
					roompassword: ""
				},
			}
		},
		methods: {
			oo() {
				let marquee = document.querySelector('.vjs-text-track-display');
				marquee.insertAdjacentHTML('beforeend',
					'<marquee  loop="1" style="color: #faad14; position: absolute;"><img width="30px" style="border-radius: 30px" src=' +
					this.$store.state.avatar + '><span>：' + this.dm + '</span></marquee>');
				this.send("弹幕|" + this.dm + "|" + this.$store.state.avatar)
				this.dm="";
			},
			// 播放(播放时会调用)(用户操作调用)
			onPlayerPlay(player) {
				if (this.arr !== null) {
					this.arr = null;
					return;
				}
				let videoPlayer2 = document.getElementById('videoPlayer2');
				this.send("play|" + this.player.cache_.currentTime)
				// 自动清理自己，避免内存泄漏

				//console.log('播放', player)
				this.$emit('onPlayerPlay', player)
			},
			// 暂停(暂停时调用)(用户操作调用)
			onPlayerPause(player) {

				if (this.arr !== null) {
					this.arr = null;
					return;
				}
				this.send("pause|0")
				this.vplay = true

				//console.log('暂停', player)
				this.$emit('onPlayerPause', player)
			},
			// 播放状态改变回调
			playerStateChanged(playerCurrentState) {
				//console.log('数据变化', playerCurrentState)
				this.$emit('playerStateChanged', playerCurrentState)
			},
			// 准备就绪(预加载前会调用)(初始化调用)将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数
			playerReadied(player) {
				//	console.log('准备就绪', player)
								this.$emit('playerReadied', player)
				
			},
			// 结束(视频播放完毕调用)
			onPlayerEnded(player) {
				//console.log('结束', player)
				this.$emit('onPlayerEnded', player)
			},
			// 等待(视频中出现loading就会调用,加载时调用)(高频调用)
			onPlayerWaiting(player) {
				//console.log('等待', player)
				this.$emit('onPlayerWaiting', player)
			},
			// 播放中(进入播放状态会调用)(等待onPlayerWaiting和播放onPlayerPlay执行后会调用)(高频调用)
			onPlayerPlaying(player) {
				//	console.log('播放中', player)
				this.$emit('onPlayerPlaying', player)
			},
			// 当播放器在当前播放位置下载数据时触发
			onPlayerLoadeddata(player) {
				//	console.log('预加载', player)
				this.$emit('onPlayerLoadeddata', player)
			},
			onPlayerRequestFullscreen(player) {
				//	console.log('预加载', player)
				this.$emit('onPlayerLoadeddata', player)
			},
			// 进度更新(当前播放位置发生变化时触发)(视频接收到新内容会一直调用)(高频调用)
			onPlayerTimeupdate(player) {

				this.$emit('onPlayerTimeupdate', player)
			},
			// 媒体的readyState为HAVE_FUTURE_DATA或更高(预加载onPlayerLoadeddata调用后会调用)(初始化调用)
			onPlayerCanplay(player) {
				//console.log('是否播放', player)
				this.$emit('onPlayerCanplay', player)
			},
			// 能够从头到尾播放(是否播放onPlayerCanplay调用后会调用)(初始化调用)这意味着可以在不缓冲的情况下播放整个媒体文件
			onPlayerCanplaythrough(player) {
				//console.log('能够从头到尾播放', player)
				this.$emit('onPlayerCanplaythrough', player)
			},



			onPause() {
				if (this.arr !== null) {
					this.arr = null;
					return;
				}
				this.send("pause|0")

				this.vplay = true
				this.$once('hook:beforeDestroy', function() {
					removeEventListener('pause', this.onPause)
				})
			},
			onPlaying() {
				if (this.arr !== null) {
					this.arr = null;
					return;
				}

				let videoPlayer = document.getElementById('videoPlayer');
				this.send("play|" + videoPlayer.currentTime)

				this.vplay = false
				// 自动清理自己，避免内存泄漏
				this.$once('hook:beforeDestroy', function() {
					removeEventListener('playing', this.onPlaying)
				})
			},
			clickPlay() {

				this.$refs.veo.play()
				this.vplay = false
				if (this.originPlay) {
					this.originPlay = false
				}
			},
			initWebSocket() {
				// 连接错误
				this.websocket.onerror = this.setErrorMessage
				// 连接成功
				this.websocket.onopen = this.setOnopenMessage
				// 收到消息的回调
				this.websocket.onmessage = this.setOnmessageMessage
				// 连接关闭的回调
				this.websocket.onclose = this.setOncloseMessage
				// 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
			},
			setErrorMessage() {
				//window.console.log('WebSocket连接发生错误，状态码：' + this.websocket.readyState)
			},
			setOnopenMessage() {
				this.send("进度|1")
				//window.console.log('WebSocket连接成功，状态码：' + this.websocket.readyState)
			},
			setOnmessageMessage(event) {

				// 根据服务器推送的消息做自己的业务处理
				this.arr = event.data.split("|");
				if (this.arr[0] === "弹幕") {
					let marquee = document.querySelector('.vjs-text-track-display');
					marquee.insertAdjacentHTML('beforeend',
						'<marquee  loop="1" style="color: #faad14; position: absolute;"><img width="30px" style="border-radius: 30px" src=' +
						this.arr[2] + '><span>：' + this.arr[1] + '</span></marquee>');
					this.arr == null;
					return;
				} else if (this.arr[0] === "进度") {
					this.player.pause();
					this.player.play();
					this.arr == null;
					return;
				} else if (this.arr[0] === "地址") {
					this.aa();
				}
				if (this.arr[0] === "play") {
					this.player.currentTime(this.arr[1]);
					this.player.play();
				} else if (this.arr[0] === "pause") {
					this.player.pause()
				}


			},
			setOncloseMessage() {
				window.console.log('WebSocket连接关闭，状态码：' + this.websocket.readyState)
			},
			onbeforeunload() {
				this.closeWebSocket()
			},
			closeWebSocket() {
				this.websocket.close()
			},
			aa() {
				if (this.splj=="") return;
				this.room.roompassword = sessionStorage.getItem('roompassword');
				this.room.roomid = sessionStorage.getItem('roomid');
				this.room.src=this.splj;
				getVideo(this.room).then(res => {
						this.playerOptions.sources[0].src = "";
						this.playerOptions.sources[0].src = res.data.src;
						this.player.pause();
						this.player.play();
						this.arr == null;
						this.send("地址|1")
				}).catch(err => {
					this.$toast({
						type: "error",
						message: err.message
					});
				});
			},
			send(message) {
				//console.log(message)
				this.websocket.send(message)
			}
		},
		mounted() {
			if (sessionStorage.getItem('roomid') == "") {
		
				this.$router.push({
					path: "/"
				})
			}
			this.playerOptions.sources[0].src = sessionStorage.getItem('roomSrc');
			if ('WebSocket' in window) {
				this.websocket = new WebSocket('ws://8.134.154.176:8800/shiyi/api/pushMessage/' + this.$store.state.nickname + "/" +
					sessionStorage.getItem('roomid'));
				this.initWebSocket();
			} else {
				alert('当前浏览器不支持WebSocket!!!')
			}
		},
		computed: {
			player() {
				return this.$refs.videoPlayer.player
			},
			cover() {
				var cover = "";
				this.$store.state.blogInfo.pageList.forEach(item => {
					if (item.pageLabel === "album") {
						cover = item.pageCover;
					}
				});
				return "background: url(" + cover + ") center center / cover no-repeat";
			}
		}
	};
</script>

<style scoped>
	* {}

	#videoPlayer2 {
		width: 100%;
		height: 100%;
	}

	.album-item {
		overflow: hidden;
		position: relative;
		cursor: pointer;
		background: #000;
		border-radius: 0.5rem !important;
	}

	.album-cover {
		position: relative;
		max-width: none;
		width: calc(100% + 1.25rem);
		height: 250px;
		opacity: 0.8;
		transition: opacity 0.35s, transform 0.35s;
		transform: translate3d(-10px, 0, 0);
		object-fit: cover;
	}

	.album-wrapper {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 1.8rem 2rem;
		color: #fff !important;
	}

	.album-item:hover .album-cover {
		transform: translate3d(0, 0, 0);
		opacity: 0.4;
	}

	.album-item:hover .album-name:after {
		transform: translate3d(0, 0, 0);
	}

	.album-item:hover .album-desc {
		opacity: 1;
		filter: none;
		transform: translate3d(0, 0, 0);
	}

	.album-name {
		font-weight: bold;
		font-size: 1.25rem;
		overflow: hidden;
		padding: 0.7rem 0;
		position: relative;
	}

	.album-name:after {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background: #fff;
		content: "";
		transition: transform 0.35s;
		transform: translate3d(-101%, 0, 0);
	}

	.album-desc {
		margin: 0;
		padding: 0.4rem 0 0;
		line-height: 1.5;
		opacity: 0;
		transition: opacity 0.35s, transform 0.35s;
		transform: translate3d(100%, 0, 0);
	}

	.ltAitabie {
		border: none;
	}

	.cct {
		width: 100%;
		height: 100%;
	}

	.fasong {
		height: 34px;
		width: 10%;
		background-color: #e8e8e8;
		text-align: center;
		line-height: 37px;
		border-radius: 4px;
		color: black;
		align-self: flex-end;
		font-size: 9%;
		cursor: pointer;
		float: right;
	}

	.marquee {
		position: absolute;
		top: 10px;
		width: 100%;
	}

	.m-video {
		display: inline-block;
		position: relative;

		.video {
			background-color: #000;
		}

		.cursor {
			cursor: pointer;
		}

		.play {
			fill: none;
			color: #FFF;
			opacity: 0.7;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			transition: opacity .3s;

			&:hover {
				opacity: 0.9;
			}
		}

		.hidden {
			display: none;
		}
	}

	.hover {
		&:hover {
			.play {
				opacity: 0.9;
			}
		}
	}
</style>
